import React, { useEffect, useState } from "react";
import AirportSelectorDeparture from "./AirportSelectorDeparture";
import AirportSelectorArrival from "./AirportSelectorArrival";
import CheapRoute from "./CheapRoute";
import DateRange from "./DateRange";
import { Button, Form, notification, Space } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import SearchResult from "./SearchResult";
import Footer from "./Footer"; // 引入Footer组件

export function isMobileDevice() {
  return (
    typeof navigator !== undefined &&
    /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
}

const App = () => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("inline");
  const isMobile = isMobileDevice();
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === "horizontal"
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;
  const [maxCountD, setMaxCountD] = useState(11);
  const [maxCountA, setMaxCountA] = useState(11);
  const [nearbyAirportsDText, setNearbyAirportsDText] = useState("");
  const [nearbyAirportsAText, setNearbyAirportsAText] = useState("");
  const [departureAirports, setDepartureAirports] = useState([]);
  const [arrivalsAirports, setArrivalsAirports] = useState([]);
  const [departureDateRange, setDepartureDateRange] = useState([
    dayjs().add(3, "days"),
    dayjs().add(10, "days"),
  ]); //代码变更时同步修改DateRange.js
  const [allDeparueAirports, setAllDeparueAirports] = useState({}); // 存储所有机场信息的字典
  const [allArrivalAirports, setAllArrivalAirports] = useState({}); // 存储所有机场信息的字典
  const [allAirportsList, setAllAirportsList] = useState({}); // 存储所有机场信息的字典
  const STATUS = {
    LOADING: "loading",
    LOADED: "loaded",
    BEFORE_SEARCH: "before_search",
  };
  const [searchStatus, setSearchStatus] = useState(STATUS.BEFORE_SEARCH);
  const [searchResult, setSearchResult] = useState([]);
  const [cheapRoute, setCheapRoute] = useState([]);
  const [isCheapLoading, setIsCheapLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [arrivalDataBack, setArrivalDataBack] = useState([]);
  const [deparueDataBack, setDeparueDataBack] = useState([]);
  const API_HOST= process.env.REACT_APP_API_HOST || "https://api.ticketradar.online";

  useEffect(() => {
    console.log(process.env, "ENV");
    let obj = JSON.parse(localStorage.getItem("history"));
    if (obj) {
      if (obj.departureAirports) {
        setDeparueDataBack(obj.departureAirports);
        setDepartureAirports(obj.departureAirports);
      }
      if (obj.arrivalsAirports) {
        setArrivalDataBack(obj.arrivalsAirports);
        setArrivalsAirports(obj.arrivalsAirports);
      }
      if (obj.departureAirports && obj.arrivalsAirports) {
        let count =
          Number(obj.arrivalsAirports?.length) +
          Number(obj.departureAirports?.length);
        setMaxCountD(11 - count);
        setMaxCountA(11 - count);
      }
      console.log("API HOST", API_HOST);
    }
    const fetchAirports = async () => {
      try {
        const response = await axios.get(`${API_HOST}/airports`);
        if (response.data.status === "success") {
          // 将机场信息转换为字典格式
          const airportsDict = {};
          response.data.data.forEach((airport) => {
            airportsDict[airport.iataCode] = airport;
          });
          setAllDeparueAirports(airportsDict);
          setAllArrivalAirports(airportsDict);
          setAllAirportsList(airportsDict);
        }
      } catch (error) {
        console.error("Failed to fetch airports:", error);
      }
    };

    const fetchCheapRoute = async () => {
      setIsCheapLoading(true);
      try {
        const response = await axios.get(`${API_HOST}/cheap-routes`);
        if (response.data.status === "success") {
          setCheapRoute(response.data.data);
          setIsCheapLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch airports:", error);
        setIsCheapLoading(false);
      }
    };
    fetchAirports();
    fetchCheapRoute();
  }, []);

  const handleAirportsChange = (type, airports) => {
    console.log(`${type} Airports:`, airports);
    if (type === "departure") {
      setDepartureAirports(airports);
      setMaxCountD(11 - Number(arrivalsAirports.length));
      setMaxCountA(11 - Number(airports.length));
    } else if (type === "arrival") {
      setArrivalsAirports(airports);
      setMaxCountA(11 - Number(departureAirports.length));
      setMaxCountD(11 - Number(airports.length));
    }
  };

  const handleDateChange = (dates) => {
    if (dates === null) {
      console.error("日期范围不能为空");
    }
    setDepartureDateRange(dates);
  };

  // 附近机场
  const fetchNearbyAirports = async (iataCode) => {
    try {
      //TODO-add: parameters for nearby airports
      const response = await axios.get(
        `${API_HOST}/airports/${iataCode}/nearby-airports`
      );
      if (response.data.status === "success") {
        console.log("Nearby Airports:", response.data.data);
        return response.data.data; // Return the data for further processing
      }
    } catch (error) {
      console.error(`Failed to fetch nearby airports for ${iataCode}:`, error);
    }
    return [];
  };

  // 搜索输入的机场
  const fetchAirports = async (key) => {
    try {
      const response = await axios.get(`${API_HOST}/airports`);
      if (response.data.status === "success") {
        // 将机场信息转换为字典格式
        const airportsDict = {};
        response.data.data.forEach((airport) => {
          if (airport.city === key) {
            airportsDict[airport.iataCode] = airport;
          }
        });
        // setAllAirports(airportsDict);
      }
    } catch (error) {
      console.error("Failed to fetch airports:", error);
    }
  };

  const handleAirportSearch = async (key, type) => {
    // fetchAirports(key);
    if (type === "A") {
      setNearbyAirportsAText("");
      setAllArrivalAirports(allAirportsList);
    } else {
      setNearbyAirportsDText("");
      setAllDeparueAirports(allAirportsList);
    }
  };

  const handleAirportSelect = async (iataCode, type) => {
    // console.log("fsdfsf", iataCode, departureAirports);
    const nearbyAirports = await fetchNearbyAirports(iataCode);
    const dict = {};
    nearbyAirports.forEach((item) => {
      dict[item.iataCode] = item;
    });
    console.log(dict);
    if (type === "A") {
      setAllArrivalAirports(dict);
    } else {
      setAllDeparueAirports(dict);
    }
    // setAllAirports(dict);
    // console.log(allAirports);
    console.debug(
      `Selected Airport Code: ${iataCode}, Nearby Airports:`,
      nearbyAirports
    );
    const airportsText = nearbyAirports
      .map(
        (airport) =>
          `${airport.city}${airport.iataCode}(${parseInt(
            airport.relativeDistance
          )}公里)`
      )
      .join("；");
    if (type === "A") {
      const item = allArrivalAirports[iataCode];
      console.log("ITEM: ", iataCode, item, allArrivalAirports);
      const cityName = item.city;
      setNearbyAirportsAText(`${cityName}${iataCode}附近机场：${airportsText}`);
    } else {
      const item = allDeparueAirports[iataCode];
      console.log("ITEM: ", iataCode, item, allDeparueAirports);
      const cityName = item.city;
      setNearbyAirportsDText(`${cityName}${iataCode}附近机场：${airportsText}`);
    }
  };
  const fetchSearchResult = async () => {
    console.log("fsdfsfa", departureAirports, departureDateRange);
    if (!departureDateRange) {
      setSearchLoading(false);
      api["error"]({
        message: "注意",
        description: "请输入出发日期！",
      });
      return;
    }
    // 构建请求参数
    const departures = departureAirports
      .map((airport) => airport.iataCode);
    const arrivals = arrivalsAirports
      .map((airport) => airport.iataCode);
    const departureStartDate = departureDateRange[0].format("YYYYMMDD");
    const departureEndDate = departureDateRange[1].format("YYYYMMDD");
    setSearchLoading(true);
    if (!(departures && departures)) {
      setSearchLoading(false);
      api["error"]({
        message: "注意",
        description: "请先输入出发和到达城市！",
      });
      return;
    }
    try {
      const requestData = {
        departures: departures,
        arrivals: arrivals,
        departure_start_date: departureStartDate,
        departure_end_date: departureEndDate,
        need_mobile_link: true
      };
      const response = await axios.post(`${API_HOST}/price/search`, requestData);
      if (response.data.status === "success") {
        console.log("Search Result:", response.data.data);
        setSearchResult(response.data.data);
        console.log("缓存", departureAirports, arrivalsAirports);
        // if (!localStorage.getItem('history')) {
        localStorage.setItem(
          "history",
          JSON.stringify({
            arrivalsAirports,
            departureAirports,
            departureStartDate,
            departureEndDate,
          })
        );
        // }
      }
      setSearchLoading(false);
    } catch (error) {
      console.error("Failed to fetch search result:", error);
      setSearchLoading(false);
    }
  };

  const switchCity = () => {
    let temp = [];
    temp = arrivalsAirports;
    setDepartureAirports(temp);
    setArrivalsAirports(departureAirports);
    console.log(departureAirports, arrivalsAirports, temp);
    setNearbyAirportsAText("");
    setAllArrivalAirports(allAirportsList);
    setNearbyAirportsDText("");
    setAllDeparueAirports(allAirportsList);
    setArrivalDataBack(departureAirports);
    setDeparueDataBack(temp);
  };

  const handleSearch = () => {
    console.log("Search Button Clicked", searchStatus);
    // setNearbyAirportsDText(''); // 清空附近机场文本
    // setNearbyAirportsAText(''); // 清空附近机场文本
    setSearchStatus(STATUS.LOADING);
    fetchSearchResult();
    setSearchStatus(STATUS.LOADED);
  };

  return (
    <div style={{ padding: "0 10px" }}>
      <div style={{ padding: 16 }}>
        {!isMobile ? (
          <h1>机票雷达，多出发地多目的地机票最低价查询(中国国内)</h1>
        ) : (
          <span style={{ fontWeight: "bold" }}>
            机票雷达，多出发地多目的地机票最低价查询(中国国内)
          </span>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
          alignItems: "stretch",
        }}
      >
        <Form
          {...formItemLayout}
          layout={formLayout}
          form={form}
          initialValues={{
            layout: formLayout,
          }}
          onValuesChange={onFormLayoutChange}
        >
          <Form.Item style={{ width: isMobile ? "100%" : "320px" }}>
            <AirportSelectorDeparture
              onAirportSelect={handleAirportSelect}
              onAirportChange={(airports) =>
                handleAirportsChange("departure", airports)
              }
              onAirportSearch={handleAirportSearch}
              allAirports={allDeparueAirports}
              maxCountD={maxCountD}
              nearbyAirportsDText={nearbyAirportsDText}
              deparueDataBack={deparueDataBack}
            />
          </Form.Item>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "8px 0px",
              }}
            >
              <SwapOutlined
                style={{
                  color: "#69b1ff",
                  fontSize: "24px",
                  marginRight: "10px",
                  cursor: "pointer",
                  rotate: "90deg",
                }}
                onClick={switchCity}
              />
            </div>
          ) : (
            <SwapOutlined
              style={{
                color: "#69b1ff",
                fontSize: "18px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={switchCity}
            />
          )}
          <Form.Item
            // label={
            //   <label style={{ fontSize: "18px", fontWeight: "bold" }}>
            //     到达城市
            //   </label>
            // }
            style={{ width: isMobile ? "100%" : "320px" }}
          >
            <AirportSelectorArrival
              onAirportSelect={handleAirportSelect}
              onAirportChange={(airports) =>
                handleAirportsChange("arrival", airports)
              }
              arrivalDataBack={arrivalDataBack}
              maxCountA={maxCountA}
              onAirportSearch={handleAirportSearch}
              allAirports={allArrivalAirports}
              nearbyAirportsAText={nearbyAirportsAText}
            />
          </Form.Item>
          {/* <Form.Item label="出发日期"> */}
          <Form.Item
            style={{
              marginTop: isMobile ? 16 : 0,
              width: isMobile ? "100%" : "320px",
            }}
          >
            <DateRange onDateChange={handleDateChange} />
          </Form.Item>
          {/* </Form.Item> */}
          {/* <Form.Item {...buttonItemLayout}> */}
          {contextHolder}
          {/* </Form.Item> */}
          {!isMobile && (
            <div style={{ width: isMobile ? "100%" : "80px" }}>
              <Button
                onClick={handleSearch}
                type="primary"
                loading={searchLoading}
                size="large"
                style={{ width: "100%" }}
              >
                {searchLoading ? "查询中" : "查找"}
              </Button>
            </div>
          )}
        </Form>
        {isMobile && (
          <Button
            onClick={handleSearch}
            type="primary"
            loading={searchLoading}
            size="large"
            style={{ width: "100%", marginTop: 24 }}
          >
            {searchLoading ? "查询中" : "查找"}
          </Button>
        )}
      </div>
      {/* <p>{nearbyAirportsDText}</p>
      <p>{nearbyAirportsAText}</p> */}
      {searchStatus === "loading" && <p>正在搜索中...</p>}
      {searchStatus === "loaded" && (
        <SearchResult
          allAirports={allAirportsList}
          searchResult={searchResult}
          searchLoading={searchLoading}
        />
      )}
      <CheapRoute allAirports={allAirportsList} cheapRoute={cheapRoute} loading={isCheapLoading} />
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
